import React from "react";
import "../styles/Marquee.css";

const Marquee = () => {
  return <div className="marquee-container aira-background-gradient">
    <div className="marquee-text fadeout-horizontal">
      <div className="marquee-text-track">
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
        <p className="mb-0"><b>FEB 10 UPDATE: </b>Chrome Plug-in for data extraction from web pages. </p>
      </div>
    </div>
  </div>
}

export default Marquee;