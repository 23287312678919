import React, { useEffect, useState } from "react";
import "../styles/FeaturesHighlight.css";
import accurateDataImage from "../assets/images/features-highlight/accurate-data.png";
import researchImage from "../assets/images/features-highlight/research.png";
import contextualMemoryImage from "../assets/images/features-highlight/contextual-memory.png";
import aiAssistantImage from "../assets/images/features-highlight/ai-assistant.png";
import anyConfusionImage from "../assets/images/features-highlight/any-confusion.png";
import plusIcon from "../assets/icons/plus.svg";
import minusIcon from "../assets/icons/minus.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const featureData = [
	{
		id: 1,
		title: "Accurate Data Extraction",
		description:
			"Streamline your workflow by eliminating manual data entry. Our powerful AI-driven technology ensures precise and efficient data extraction, creating your candidate profile with one simple file upload.",
		highlight: "Simply drag and drop as many resumes as you'd like and watch Aira do the work!",
		image: accurateDataImage,
		name: 'accurate-data-extraction'
	},
	{
		id: 2,
		title: "Research",
		description:
			"Introduced to a new market? Search for industry insights, job market trends, new candidates - all within the system that learns as you do.",
		image: researchImage,
		name: 'research',
	},
	{
		id: 3,
		title: "Contextual Memory",
		description:
			"An assistant that continues learning and never forgets - insights can always be brought back up when necessary, with contextual understanding. Just Ask Aira.",
		image: contextualMemoryImage,
		name: 'contextual-memory',
	},
	{
		id: 4,
		title: "AI Assistant",
		description:
			"All the features of a traditional Applicant Tracking System (ATS) but with a twist. Simply chat with the system like you would with a friend. There is no longer the need to figure out complicated systems.",
		image: aiAssistantImage,
		name: 'ai-assistant',
	},
	{
		id: 5,
		title: "Any confusion? Just Ask Aira.",
		description:
			"Aira's here to help you with every step along the way. Curious as to how to best utilize Aira's abilities? Just Ask.",
		image: anyConfusionImage,
		name: 'confusion',
	},
];

const FeaturesHighlight = () => {
	const [hoverId, setHoverId] = useState(1);
	const [openIds, setOpenIds] = useState([]);

	const handleMouseEnter = (id) => {
		setHoverId(id);
	};

	const handleMouseLeave = () => {
		setHoverId(null);
	};

	const handleClick = (id) => {
		setOpenIds((prev) =>
			prev.includes(id) ? prev.filter((openId) => openId !== id) : [...prev, id]
		);
	};

	const FeatureCard = ({ feature, openIds, hoverId, handleMouseEnter, handleMouseLeave, handleClick }) => {
		const isOpen = openIds.includes(feature.id) || hoverId === feature.id;

		return (
			<div
				className={`fh-card ${feature.name}`}
				onMouseEnter={() => handleMouseEnter(feature.id)}
				onMouseLeave={handleMouseLeave}
				onClick={() => handleClick(feature.id)}
			>
				<div className={`d-flex align-items-center justify-content-between fh-header ${isOpen ? "open" : ""}`}>
					<h5 className="m-0 fw-bold fh-title">{feature.title}</h5>
					<img src={isOpen ? minusIcon : plusIcon} alt="Toggle Icon" className={isOpen ? "minus-icon" : "plus-icon"} />
				</div>
				<div className={`row pt-0 fh-body ${isOpen ? "open" : ""}`}>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center feature order-md-first order-last mt-md-0 mt-4">
						<div className="fh-content">
							<h2 className="fw-bold fh-title">{feature.title}</h2>
							<p className="my-4">{feature.description}</p>
							{feature.highlight && <p className="fw-bold mb-5 gradient-text">{feature.highlight}</p>}
						</div>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center order-md-last order-first">
						<img src={feature.image} alt={feature.title} className="img-fluid" />
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		AOS.init({
			duration: 750, // Animation duration
			easing: "ease-out", // Easing function
			once: true, // Whether animation should happen only once
		});
	}, []);

	return (
		<div className="features-highlight py-5">
			<div className="container pb-5">
				{/* Subtext */}
				<div className="row" data-aos="fade-up">
					<div>
						<p className="subtext rounded-pill">What can Aira do?</p>
					</div>
				</div>
				<div className="fh-container" data-aos="fade-up">
					{featureData.map((feature) => (
						<FeatureCard
							key={feature.id}
							feature={feature}
							openIds={openIds}
							hoverId={hoverId}
							handleMouseEnter={handleMouseEnter}
							handleMouseLeave={handleMouseLeave}
							handleClick={handleClick}
						/>
					))}
				</div>
			</div>
		</div >
	);
};

export default FeaturesHighlight;
