import React from "react";
import Testimonial from "./ui/Testimonial/Testimonial";
import "../styles/Testimonials.css";

const testimonialsData = [
	{
		id: 1,
		name: "Daniel B.",
		company: "Enigma Recruitment",
		role: "Industrial Recruitment",
		review: 5,
		quote:
			"I thought our current Recruitment software was the best in the market until having hands on experience with AIRA. There are lots of updates being rolled out by the team and we are very actively keeping up to date with what is happening as the potential for AIRA and the time/efficiency it can bring is exponential.",
	},
	{
		id: 2,
		name: "Pisey K.",
		company: "Workingna",
		role: "HR & Recruitment",
		review: 5,
		quote:
			"Aira has transformed the way we work by streamlining processes and allowing us to focus on strategic tasks, which has significantly boosted our team’s productivity. Its accuracy and reliability have exceeded our expectations, enabling us to enhance customer service satisfaction.",
	},
	// Add more testimonials as needed
];

const Testimonials = () => {
	return (
		<div className="testimonials py-5">
			<div className="container">
				<div className="text-center my-3">
					<h2 className="fw-lighter">We Made Hiring Easier.</h2>
				</div>
				<div className="row">
					<div>
						<p className="subtext rounded-pill">What do your real users say?</p>
					</div>
				</div>
				<div className="row py-3 pb-5 row-gap-4">
					{testimonialsData.map(testimonial => (
						<div className="col-sm-6" key={testimonial.id}>
							<Testimonial
								key={testimonial.id}
								name={testimonial.name}
								company={testimonial.company}
								role={testimonial.role}
								review={testimonial.review}
								quote={testimonial.quote}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Testimonials;
