import React from "react";
import { Star } from "lucide-react";
import quotationMark from "../../../assets/images/quotation-mark.png";
import avatar from "../../../assets/images/avatar.png";
import "./Testimonial.css";

const Testimonial = ({ name, company, role, review, quote }) => {
	const renderStars = count => {
		return Array.from({ length: count }).map(() => (
			<Star fill="#FFE227" strokeWidth={0} />
		));
	};

	return (
		<div className="testimonial-card">
			<div className="quotation-mark">
				<img src={quotationMark} alt="Quotation Mark" width={43} />
			</div>
			<div className="review py-3">
				<p>{quote}</p>
			</div>
			<div className="d-flex align-items-center gap-3">
				<div className="avatar">
					<img src={avatar} alt="Avatar" />
				</div>
				<div>
					<p className="mb-0">{name} | <span className="company">{company}</span> in {role}</p>
					{renderStars(review)}
				</div>
			</div>
		</div>
	);
};

export default Testimonial;
