import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { animateScroll as scroll } from "react-scroll";
import Footer from "../Common/Footer";
import ContactUs from "../components/Contact/ContactUs";


const Contact = () => {
  useEffect(() => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
      offset: -70, // Adjust this value if you have a fixed header
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Aira | Contact</title>
        <meta name="title" content="Aira | Contact" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta
          property="og:image"
          content="/assets/images/meta/aira_social_media_preview.png"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:image"
          content="/assets/images/meta/aira_social_media_preview.png"
        />
      </Helmet>
      <div id="contact-top">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
