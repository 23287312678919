import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/"); // Redirect back to home page
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <div className='text-center py-5'>
      <h2>🎉 Submission Successful!</h2>
      <p>You will be redirected back to home in 5 seconds.</p>
      <button
        onClick={() => navigate("/")}
        className="my-3 aira-background-gradient border-0 py-3 px-5 rounded-4 text-white"
      >
        Go to Home
      </button>
    </div>
  )
}

export default SuccessPage