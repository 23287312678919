import React from "react";
import "../styles/NativelyBuilt.css";
import airaAvatar from "../assets/images/aira-avatar.png";
import { useNavigate } from "react-router-dom";

const NativelyBuilt = () => {
	const BOOK_DEMO_LINK = "https://calendly.com/d/ck5g-53m-559/aira-demo";
	const navigate = useNavigate();

	return (
		<div className="natively-built">
			<h2 className="fw-lighter">We're natively built on AI.</h2>
			<div className="content-wrapper py-2">
				<div className="conversation-container">
					<div className="right-section text-end">
						<p>What does that mean?</p>
					</div>
					<div className="left-section my-3 mb-4">
						<div className="d-flex gap-3">
							<div className="flex-shrink-0">
								<img src={airaAvatar} alt="Aira Avatar" width={24} />
							</div>
							<p>I help with the tedious tasks, while getting better at assisting you with every interaction.</p>
						</div>
					</div>
					<div className="right-section text-end">
						<p>Awesome! Where do I begin?</p>
					</div>
					<div className="d-flex my-3 gap-3">
						<div className="flex-shrink-0">
							<img src={airaAvatar} alt="Aira Avatar" width={24} />
						</div>
						<div className="button-container">
							<button className="beta-btn"
								onClick={() =>
									navigate('/contact')
								}>Join Beta Testing</button>
							<button className="demo-btn aira-background-gradient" onClick={() =>
								window.open(BOOK_DEMO_LINK, "_blank", "noopener,noreferrer")
							}>Book a demo</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NativelyBuilt;
