import React from "react";
import arrowRightIcon from "../assets/icons/arrow-right.svg";
import airaSample from "../assets/images/aira-sample.png";
import "../styles/HeroBanner.css";
import "../styles/Popup.css";

const HeroBanner = () => {
	return (
		<div className="container-fluid py-5 hero-banner">
			<div className="d-flex flex-column align-items-center">
				<h1 className="text-black text-center mb-3 py-3 fw-lighter">
					Effortless Recruitment with Agentic AI.
				</h1>
				<p className="fs-5 text-black text-center mb-3 blog-size">
					Aira streamlines hiring with intuitive, scalable solutions, enabling
					<br />
					seamless collaboration and unparalleled efficiency.
				</p>
				<button
					className="btn aira-background-gradient py-3 my-5 fw-semibold d-flex gap-3 justify-content-center align-items-center"
					onClick={() =>
					(window.location.href =
						"https://calendly.com/d/ck5g-53m-559/aira-demo")
					}
				>
					Book A Demo
					<img src={arrowRightIcon} alt="Arrow Icon" />
				</button>
				<div className="container my-5">
					<div className="row">
						<div>
							<img src={airaSample} alt="Aira Sample" className="aira-sample" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroBanner;
