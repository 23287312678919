import React from "react";
import { ReactSVG } from "react-svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Footer.css";
import airaLogo from "../assets/images/aira-logo.svg";
import lemon8Logo from "../assets/icons/lemon8-logo.svg";

const Footer = () => {
	return (
		<footer className="footer py-4 pt-5">
			<div className="container">
				<div className="row mb-5">
					<div className="col-lg-4 col-12 text-left">
						<ul className="footer-menu fw-bold">
							<li>
								<a href="#product">Product</a>
							</li>
							<li>
								<a href="#features">Features</a>
							</li>
							<li>
								<a href="#resources">Resources</a>
							</li>
						</ul>
					</div>
					<div className="col-lg-4 col-12 text-center my-lg-0 my-5">
						<img className="footer-logo" src={airaLogo} alt="Aira Logo" />
					</div>
					<div className="col-lg-4 col-12 text-right">
						<ul className="footer-menu fw-bold">
							<li>
								{/* TODO: Make this link to '/about' when the about page is done! */}
								<a href="#about">About</a>
							</li>
							<li>
								<a href="/blogs">Blog</a>
							</li>
							<li>
								{/* TODO: Make this link to '/support' when the support page is done! */}
								<a href="#support">Support</a>
							</li>
						</ul>
					</div>
				</div>
				<hr className="footer-divider mb-5" />

				<div className="row mb-3 justify-content-between align-items-center">
					<div className="col-md-4">
						<p className="text-md-start text-center">
							BlockchainLabs.ai Co., Ltd.
							<br />
							29 Floor, 253 Asok Montri Rd, Khlong Toei
							<br />
							Nuea, Watthana, Bangkok 10110
							<br />
							Tax ID 0105561179526
						</p>
					</div>
					<div className="col-md-4 text-center">
						<div>
							{/* CURRENT: TODO: Fix the alignment issues for the footer icons when it is squished */}
							<div className="row justify-content-center">
								<div className="col-auto">
									<a
										target="_blank"
										href="https://www.facebook.com/helloaira.io/"
										className="social-icon text-decoration-none"
										rel="noopener noreferrer"
									>
										<i className="fa-brands fa-facebook-f"></i>
									</a>
								</div>
								<div className="col-auto">
									<a
										target="_blank"
										href="https://www.linkedin.com/company/helloaira-io/"
										className="social-icon text-decoration-none"
										rel="noopener noreferrer"
									>
										<i className="fab fa-linkedin-in"></i>
									</a>
								</div>
								<div className="col-auto">
									<a
										target="_blank"
										href="https://www.tiktok.com/@helloaira.io"
										className="social-icon text-decoration-none"
										rel="noopener noreferrer"
									>
										<i className="fa-brands fa-tiktok"></i>
									</a>
								</div>

								<div className="row justify-content-center mt-3">
									<div className="col-auto">
										<a
											target="_blank"
											href="https://www.instagram.com/helloaira.io/"
											className="social-icon text-decoration-none"
											rel="noopener noreferrer"
										>
											<i className="fa-brands fa-instagram"></i>
										</a>
									</div>
									<div className="col-auto">
										<a
											target="_blank"
											href="https://www.lemon8-app.com/aira_wn"
											className="social-icon text-decoration-none"
											rel="noopener noreferrer"
										>
											<i>
												<ReactSVG src={lemon8Logo} />
											</i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 text-md-end text-center right-side-info mt-md-0 mt-3">
						<div className="mb-2">
							<p className="privacy-terms">
								<a href="#privacy-terms"> Security - Privacy - Terms</a>
							</p>
							<p>&copy; 2024 helloaira.io</p>
						</div>
						<div className="contact-info">
							<p>
								<a href="mailto:contact@helloaira.io">contact@helloaira.io</a>
							</p>
							<p>
								<a href="tel:+66908984052">+66908984052</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
