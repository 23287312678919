import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logoImg from "../assets/images/logo1@2x.png";
import "../styles/Header.css";

const Header = () => {
	const navbarCollapseRef = useRef(null);

	const handleNavLinkClick = () => {
		if (navbarCollapseRef.current) {
			navbarCollapseRef.current.classList.remove("show");
		}
	};

	return (
		<header className="py-3 position-relative">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-12 d-flex justify-content-between">
						<nav className="navbar navbar-expand-lg navbar-light p-0 w-100">
							<button
								className="navbar-toggler"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#navbarMobile"
								aria-controls="navbarMobile"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
							<Link className="navbar-brand ml-auto" to="/">
								<img src={logoImg} alt="Logo" width="72" height="28" />
							</Link>
							<div className="collapse navbar-collapse" id="navbarMobile">
								<ul className="navbar-nav">
									<li className="nav-item active">
										<Link
											className="nav-link"
											to="/"
											onClick={handleNavLinkClick}
										>
											Home
										</Link>
									</li>
									<li className="nav-item">
										<Link
											className="nav-link"
											to="/blogs"
											onClick={handleNavLinkClick}
										>
											Blogs
										</Link>
									</li>
									<li className="nav-item">
										<Link
											className="nav-link"
											to="/pricing"
											onClick={handleNavLinkClick}
										>
											Pricing
										</Link>
									</li>
								</ul>
								<div className="ms-auto d-flex">
									<button className="btn btn-login">Login</button>
									<button className="btn btn-try-free ms-2">Get Started</button>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
